@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  margin: 0;
}

@import "react-datepicker/dist/react-datepicker.css";
@font-face {
  font-family: 'MarkPro';
  src:local("MarkPro"),
  url('./font/MarkPro.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'MarkPro';
  src:local("MarkPro"),
  url('./font/MarkProMedium.ttf') format('truetype');
  font-weight: 500;
}


body {
  margin: 0;
  font-family: MarkPro, sans-serif;
  font-style: normal;
  color: #101941;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 12px;
  color: #101941;
}


@font-face {
  font-family: 'fontello';
  src: url('./font/fontello.eot?47843102');
  src: url('./font/fontello.eot?47843102#iefix') format('embedded-opentype'), url('./font/fontello.woff?47843102') format('woff'), url('./font/fontello.ttf?47843102') format('truetype'), url('./font/fontello.svg?47843102#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px dimgray;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: gray;
}

input, div, a, p, html {
  font-family: MarkPro, sans-serif;
}